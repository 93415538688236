import React, { FC } from "react";
import poster from '../images/india/india.png'
import { css, Global } from "@emotion/react";
import { Helmet } from "react-helmet";

const IndiaPage: FC = () => {
  return (
    <div css={css`display: flex; justify-content: center;`}>
      <Global styles={css`body { margin: 0;}`} />
      <a id="india_click_download_now" href="https://app.adjust.com/d8wvtx?campaign=IN_Branch&adgroup=Hannah_VideoTest&creative=[PD:IN]Global&deep_link=azarlive%3A%2F%2Fdiscover">
        <Helmet>
          <meta charSet='utf-8' />
          <title>Azar</title>
        </Helmet>
        <img
          src={poster}
          alt="azar"
          css={css`height: 100vh;`}
        />
      </a>
    </div>
  )
}

export default IndiaPage
